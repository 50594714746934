import React from "react";
import styled from "styled-components";
import theme from "styled-theming";

const color = theme("mode", {
  light: "#4a4a4a",
  dark: "#afafaf"
});

const Logo = styled(HnsScan)`
  fill: ${color};
  stroke: ${color};
`;

function HnsScan(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 554 407">
      <title>HNScan Logo</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <path
            {...props}
            d="M139.474416,270 L141.695809,270 C166.188793,320.321233 217.817742,355 277.546352,355 C337.274961,355 388.903911,320.321233 413.396889,270.00001 L415.618288,270 C390.975449,321.459854 338.411323,357 277.546352,357 C216.68138,357 164.117254,321.459854 139.47442,270.000009 Z M139.000004,138.999992 C163.437983,87.0011407 216.287909,51 277.546352,51 C338.804795,51 391.654721,87.0011407 416.0927,138.999992 L413.879068,139 C389.58615,88.1387753 337.669801,53 277.546352,53 C217.422902,53 165.506553,88.1387753 141.213639,138.999991 L139,139 Z"
            stroke="#000000"
            strokeWidth="4"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            {...props}
            d="M180.676863,270 L183.11055,270 C203.912904,299.631801 238.343541,319 277.297863,319 C316.252184,319 350.682821,299.631801 371.485175,270 L373.918862,270 C352.843629,300.79382 317.430449,321 277.297863,321 C237.165276,321 201.752096,300.79382 180.676863,270 Z M180,139 C200.988795,107.644371 236.732453,87 277.297863,87 C317.863272,87 353.60693,107.644371 374.595725,139 L372.179351,139 C351.4539,108.804704 316.6887,89 277.297863,89 C237.907025,89 203.141825,108.804704 182.416374,139 L180,139 Z"
            stroke="#000000"
            strokeWidth="4"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            {...props}
            d="M85,269 L106.264342,269 C132.637592,337.998297 199.4609,387 277.729169,387 C355.997437,387 422.820745,337.998297 449.193982,269.000002 L470.458337,269 C443.191552,349.255355 367.203852,407 277.729169,407 C188.254485,407 112.266785,349.255355 85.0000066,269.000001 Z M85.0000066,137.999999 C112.266785,57.7446451 188.254485,0 277.729169,0 C367.203852,0 443.191552,57.7446451 470.458331,137.999999 L449.193995,138 C422.820745,69.0017028 355.997437,20 277.729169,20 C199.4609,20 132.637592,69.0017028 106.264355,137.999998 L85,138 Z"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            {...props}
            d="M355.5,75.512442 L358.220987,75.512442 L355.59312,71.1544279 L353,75.512442 L355.5,75.512442 L358,75.512442 L355.40688,71.1544279 L352.779013,75.512442 L355.5,75.512442 Z M355.396212,76.8255727 L358,72.5074915 L353,72.5074915 L355.603788,76.8255727 L358.173147,72.5074915 L352.826853,72.5074915 L355.396212,76.8255727 Z M368,81.1985865 L355.5,88.6363767 L343,81.1985865 L343,66.8601079 L355.5,59.3226506 L368,66.8601079 L368,81.1985865 Z"
            stroke="#000000"
            strokeWidth="8"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            {...props}
            d="M192.5,330.512442 L195.220987,330.512442 L192.59312,326.154428 L190,330.512442 L195,330.512442 L192.40688,326.154428 L189.779013,330.512442 L192.5,330.512442 Z M192.396212,331.825573 L195,327.507491 L190,327.507491 L192.603788,331.825573 L195.173147,327.507491 L189.826853,327.507491 L192.396212,331.825573 Z M205,336.198586 L192.5,343.636377 L180,336.198586 L180,321.860108 L192.5,314.322651 L205,321.860108 L205,336.198586 Z"
            stroke="#000000"
            strokeWidth="8"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            {...props}
            d="M277.5,8 L277.5,88"
            stroke="#000000"
            strokeWidth="6"
            strokeLinecap="square"
          ></path>
          <path
            {...props}
            d="M276.5,322 L276.5,402"
            stroke="#000000"
            strokeWidth="6"
            strokeLinecap="square"
          ></path>
          <path
            {...props}
            d="M66.93,161.5 L81.305,161.5 L81.305,242 L66.93,242 L66.93,205.085 L15.18,205.085 L15.18,242 L0.805,242 L0.805,161.5 L15.18,161.5 L15.18,190.71 L66.93,190.71 L66.93,161.5 Z M163.93,161.5 L178.305,161.615 L178.305,242.115 L163.93,231.075 L112.18,191.17 L112.18,242.115 L97.805,242.115 L97.805,161.845 L112.18,172.885 L163.93,212.79 L163.93,161.5 Z M270.935,183.695 L256.56,183.81 L256.56,183.35 C256.56,179.325 253.225,175.99 249.2,175.99 L216.54,175.99 C212.515,175.99 209.18,179.325 209.18,183.35 L209.18,183.81 C209.18,187.835 212.515,191.17 216.54,191.17 L245.52,191.17 C252.995,191.17 259.665,194.39 264.265,199.45 C264.61,199.795 264.84,200.14 265.185,200.485 C268.75,204.855 270.935,210.49 270.935,216.585 C270.935,230.615 259.55,242 245.52,242 L220.335,242 C206.305,242 194.92,230.615 194.92,216.585 L209.295,216.585 C209.295,222.68 214.24,227.625 220.335,227.625 L245.52,227.625 C251.615,227.625 256.675,222.68 256.675,216.585 C256.675,210.49 251.615,205.545 245.52,205.545 L216.54,205.545 C210.675,205.545 205.385,203.245 201.475,199.45 C200.44,198.415 199.52,197.265 198.715,196 C196.3,192.55 194.92,188.295 194.92,183.695 L194.92,183.235 C194.92,171.275 204.58,161.5 216.54,161.5 L249.2,161.5 C261.16,161.5 270.935,171.275 270.935,183.235 L270.935,183.695 Z M326.535,175.875 C312.275,175.875 300.66,187.49 300.66,201.75 C300.66,216.01 312.275,227.625 326.535,227.625 L366.785,227.625 L366.785,242 L326.535,242 C304.34,242 286.285,223.945 286.285,201.75 C286.285,179.555 304.34,161.5 326.535,161.5 L366.785,161.5 L366.785,175.875 L326.535,175.875 Z M453.435,227.625 L460.795,242 L444.695,242 L437.335,227.625 L419.74,193.125 L402.145,227.625 L394.785,242 L378.685,242 L386.045,227.625 L419.74,161.5 L453.435,227.625 Z M538.82,161.5 L553.195,161.615 L553.195,242.115 L538.82,231.075 L487.07,191.17 L487.07,242.115 L472.695,242.115 L472.695,161.845 L487.07,172.885 L538.82,212.79 L538.82,161.5 Z"
            fill="#000000"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
